/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import CookieConsent from "react-cookie-consent";
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>
        <footer
          style={{
            marginTop: `2rem`,
          }}
        >
          
        <p>
            Autor: <a href="https://www.centarzdravlja.hr/" target="_blank" rel="noopener noreferrer">CentarZdravlja</a><br />
            Za više informacija posjetite <a href="https://www.vichy.hr/le-vichy-mag/nema-pauze-u-menopauzi/vmag75957.aspx" target="_blank" rel="noopener noreferrer">"Nema pauze u menopauzi"</a> <br/>
            Facebook grupa <a href="https://www.facebook.com/groups/832972320212227/" target="_blank" rel="noopener noreferrer">"Nema pauze u menopauzi"​</a>
        </p>
        </footer>

        <CookieConsent
          location="bottom"
          buttonText="Prihvati"
          declineButtonText="Decline">
          <span style={{ fontSize: "13px" }}>U skladu s novom europskom regulativom, Centarzdravlja.hr je nadogradio politiku privatnosti i korištenja kolačića. Kolačiće upotrebljavamo kako bismo vam omogućili korištenje naše online usluge, što bolje korisničko iskustvo i funkcionalnost naših web stranica, prikaz reklamnih sadržaja i ostale funkcionalnosti koje inače ne bismo mogli pružati. Daljnjim korištenjem naše web stranice, suglasni ste s korištenjem kolačića. 
          <a className="policy" href="https://www.centarzdravlja.hr/info/uvjeti/izjava-o-privatnosti/" target="_blank" rel="noopener noreferrer"> Saznaj više</a>
          </span>
        </CookieConsent>

      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
