import * as React from "react"
import * as styles from '../styles/hero.module.css'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';


const Header = () => {
  const { placeholderImage123 } = useStaticQuery(
          graphql`
      query {
        placeholderImage123: file(relativePath: {eq: "hero.png"}) {
          childImageSharp {
            gatsbyImageData(
              width: 2000
              quality: 50
              webpOptions: {quality: 70}
              placeholder: BLURRED
            )
          }
        }
      }
    `
  )
  const pluginImage = getImage(placeholderImage123);

return (
  <header className={styles.hero}>
    <div>
    <BgImage image={pluginImage} className={styles.heroOverlay}>
      <div className={styles.center}>
        <div className={styles.wrapper}>
          <h1>Farmaceutkinje u <br/>službi ljepote</h1>
        </div>
      </div>
    </BgImage>
    </div>
  </header>
  );
}

export default Header
